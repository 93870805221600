import { Fancybox } from '@fancyapps/ui';
import '../../../node_modules/@fancyapps/ui/dist/fancybox.css';

$('[data-gallery]').each(function() {
    $(this).on('click', function(event) {
        event.preventDefault();

        let images = $(this).attr('data-gallery').split(',');

        let gallery = [];
        $(images).each(function(index, element) {
            gallery.push({src: element, thumb: element});
        });

        showGallery(gallery);
    });
});

function showGallery (gallery) {
    Fancybox.show(gallery);
}