window.trackingParcelOnResize = function () {
    $('.c-parcel-tracking-steps-wrapper').each(function(index, element) {
        let statelineProgress = $(element).find('.c-parcel-tracking-steps__stateline').attr('data-progress');
        let warningProgress = $(element).find('.c-parcel-tracking-steps__item--warning').attr('data-progress');
        let tooltipProgress = $(element).find('.c-parcel-tracking-steps__tooltip').attr('data-progress');
    
        if (window.matchMedia('(max-width: 991px)').matches) {
            // Progress line for mobile vertical display
            $(element).find('.c-parcel-tracking-steps__stateline').css({'width': '2px', 'height': statelineProgress});
    
            // Warning icon for mobile vertical display
            $(element).find('.c-parcel-tracking-steps__item--warning').css({'top': warningProgress, 'left': 'auto'});
    
            // Tooltip for mobile vertical display
            $(element).find('.c-parcel-tracking-steps__tooltip').css({'left': '37px', 'top': tooltipProgress});
        }else{
            // Progress line for desktop vertical display
            $(element).find('.c-parcel-tracking-steps__stateline').css({'width': statelineProgress, 'height': '2px'});
    
            // Warning icon for desktop vertical display
            $(element).find('.c-parcel-tracking-steps__item--warning').css({'left': warningProgress, 'top': 'auto'});
    
            // Tooltip for desktop vertical display
            $(element).find('.c-parcel-tracking-steps__tooltip').css({'left': tooltipProgress, 'top': '17px'});
        }
    });
}

window.trackingParcelOnResize();

$(window).on('resize', window.trackingParcelOnResize);