// Display modal on checked for search page tracking (connected)
$('.js-signature-checkbox').on('focus', function(e) {    
    if(!this.checked) {
        $('#modal-signature').modal('show');
        $(this).prop('checked', !$(this).prop('checked'));
    }
});

$('.js-btn-accept-signature').on('click', function(e) {
    e.preventDefault();
    $('.js-signature-checkbox').prop('checked', true);
    $('#modal-signature').modal('hide');
});