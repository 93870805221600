import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap.registerPlugin(ScrollTrigger);

// gsap.utils.toArray('[data-scroll]').forEach((el, i) => {
//     gsap.from(el, {
//         y: 100,
//         opacity: 0,
//         duration: 1,
//         ease: 'power2.out',
//         scrollTrigger: {
//             trigger: el,
//             end: '+=200',
//         }, 
//     });
// });

import LocomotiveScroll from 'locomotive-scroll';

(() => {

    document.documentElement.classList.add('is-loaded');
    document.documentElement.classList.remove('is-loading');

    setTimeout(() => {
        document.documentElement.classList.add('is-ready');
    },300)

    setTimeout(() => {
        const scroll = new LocomotiveScroll({
            smooth: false
        });

        scroll.on('call', (value, way, obj) => {
            if (value === 'genericScrollAnimation') {
                if(way === 'enter') {

                    let items = obj.el.querySelectorAll('[data-scroll-item]');

                    let delay = 0;

                    items.forEach(item => {
                        gsap.to(item, {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.5,
                            delay: delay,
                            ease: 'power2.out'
                        });

                        delay += 0.2;
                    });
                }
            }else if(value === 'aboutKeywordsScrollAnimation') {
                let itemfromLeft = obj.el.querySelector('[data-scroll-item-from-left]');
                let itemfromRight = obj.el.querySelector('[data-scroll-item-from-right]');

                let delay = 0.1;
                
                if(itemfromLeft) {
                    gsap.to(itemfromLeft, {
                        x: 0,
                        autoAlpha: 1,
                        duration: 0.8,
                        delay: delay,
                        ease: 'power2.out'
                    });
                }

                if(itemfromRight) {
                    gsap.to(itemfromRight, {
                        x: 0,
                        autoAlpha: 1,
                        duration: 0.8,
                        delay: delay,
                        ease: 'power2.out'
                    });
                }
            }
        });
    }, 1000)

})();