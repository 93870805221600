window.triggerAccordions = function() {
    // Sending History Accordion Behaviors
    // Target Card Header for Styling Purpose
    $('.js-accordion-history, .js-accordion-faq').on('show.bs.collapse', function (event) {
        if ($(this).is(event.target)) {
            $(this).prev('.card-header').addClass('collapsed');
        }
    })

    $('.js-accordion-history, .js-accordion-faq').on('hide.bs.collapse', function (event) {
        if ($(this).is(event.target)) {
            $(this).prev('.card-header').removeClass('collapsed');
        }
    })
}

window.triggerAccordions()
