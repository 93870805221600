// import $ from 'jquery';
// window.$ = $;
import 'bootstrap';
import 'bootstrap-select';
import Inputmask from 'inputmask';
import Pikaday from 'pikaday';
import SmoothScroll from 'smooth-scroll';

require('./components/Accordion');
require('./components/PhotoGallery');
require('./components/PopoverOpenModal');
require('./components/ModalVideo');
require('./components/ModalCheckbox');
require('./components/InputSelectColor');
require('./components/Parallax');
require('./components/ParcelTrackingSteps');
require('./components/JobsFiltering');

// Inputmask().mask(document.querySelectorAll('input'));

Inputmask({"mask": "999 999-9999"}).mask(document.querySelectorAll('.inputmask-phone'));

$(function () {
   $('[data-toggle="popover"]').popover({
       sanitize: false // Because we need data attributes!
   });
   $('[data-toggle="tooltip"]').tooltip();
});

// Pikaday Date Picker
var pikadayFrConfig = {
	previousMonth	: 'Mois précédent',
	nextMonth		: 'Mois prochain',
	months 			: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
	weekdays		: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
	weekdaysShort	: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
};

$('.js-date-picker').each(function( index, element ) {
    var picker = new Pikaday({
        field: element
    });

    if($('html')[0].lang == 'fr') {
        picker._o.i18n = pikadayFrConfig;
        picker._o.format = 'DD-MM-YYYY';
    }
});

var scroll = new SmoothScroll('[js-smooth-scroll]', {
    offset: 140
});

// Reboot Botstrap Tooptip
window.rebootTooltip = function(){
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
}

// Modal
window.modalAction = function(modal, action){
    $(modal).modal(action);
}

// Reboot Collapse
window.rebootCollapse = function(){
    $('[data-toggle="collapse"]').collapse();
}
