// Handle popvers links
// Required data attributes:
// Button: class="js-popover-button"
// Link: data-toggle='modal' data-modal='#my-modal'
// Example:
/*
<button class="btn-table-popover-more js-popover-button" role="button" data-toggle="popover" data-trigger="focus" data-placement="right" data-custom-class="popover-dark" data-html="true" data-content="<div class='text-center'><a class='d-block my-2' href='#' data-toggle='modal' data-modal='#modal-cancel' data-user-id='12345'>Annuler l’envoi</a></div>"></button>
*/

$('.js-popover-button').each(function(index) {
    $(this).on('click', function(e) {
        e.preventDefault();
    }); 

    $(this).on('shown.bs.popover', function (event) {
        $($(this).data('bs.popover').tip).find("*[data-toggle='modal']").on('click', function(e) {
            e.preventDefault();
            let modal = $(e.target).attr('data-modal');
            $(modal).modal('show', e.target);
        });
    });
});

// Example of fetching parameter
// $('#modal-cancel').on('show.bs.modal', function (event) {
//     let params = $(event.relatedTarget).attr('data-user-id');
//     console.log(params);
// })