var $filterCheckboxes = $('.js-jobs-filters input[type=checkbox]');

var filterFunc = function() {


    var selectedFilters = {};

    $filterCheckboxes.filter(':checked').each(function() {

        if (!selectedFilters.hasOwnProperty(this.name)) {
            selectedFilters[this.name] = [];
        }

        selectedFilters[this.name].push(this.value);
    });

    var $filteredResults = $('.js-job-item');

    $.each(selectedFilters, function(name, filterValues) {

        $filteredResults = $filteredResults.filter(function() {

            var matched = false,
                currentFilterValues = $(this).data('category').split(' ');
                console.log(currentFilterValues)

            $.each(currentFilterValues, function(_, currentFilterValue) {

                if ($.inArray(currentFilterValue, filterValues) != -1) {
                    matched = true;
                    return false;
                }
            });

            return matched;

        });

    });

    $('.js-job-item').addClass('d-none');
    $('.js-job-item').filter($filteredResults).removeClass('d-none');
}

$filterCheckboxes.on('change', filterFunc);